// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-and-security-js": () => import("./../../../src/pages/privacy-and-security.js" /* webpackChunkName: "component---src-pages-privacy-and-security-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-single-blog-1-js": () => import("./../../../src/pages/single-blog-1.js" /* webpackChunkName: "component---src-pages-single-blog-1-js" */),
  "component---src-pages-single-blog-2-js": () => import("./../../../src/pages/single-blog-2.js" /* webpackChunkName: "component---src-pages-single-blog-2-js" */),
  "component---src-pages-single-blog-3-js": () => import("./../../../src/pages/single-blog-3.js" /* webpackChunkName: "component---src-pages-single-blog-3-js" */),
  "component---src-pages-single-portfolio-js": () => import("./../../../src/pages/single-portfolio.js" /* webpackChunkName: "component---src-pages-single-portfolio-js" */),
  "component---src-pages-single-services-js": () => import("./../../../src/pages/single-services.js" /* webpackChunkName: "component---src-pages-single-services-js" */),
  "component---src-pages-team-1-js": () => import("./../../../src/pages/team-1.js" /* webpackChunkName: "component---src-pages-team-1-js" */),
  "component---src-pages-team-2-js": () => import("./../../../src/pages/team-2.js" /* webpackChunkName: "component---src-pages-team-2-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

